import React from 'react'
import * as Sentry from '@sentry/gatsby'
import { createLeadHelper, localStore } from '@flock/utils'
import {
  BathroomIcon,
  BedroomIcon,
  DimensionsIcon,
  HouseOutlinedIcon,
  InputType,
  HouseFilledIcon,
  TrackedLink,
  FLOCK_EMAIL,
  StepConfig,
  AnyInputConfig,
} from '@flock/shared-ui'
import { Box, CircularProgress, Typography } from '@mui/material'
import {
  Core_HomeDetails,
  Core_TransactionType,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { navigate } from 'gatsby'
import { abbreviationToState, decomposeSlackUrl, isValidMarket } from '../utils'
import WebflowStep, {
  InjectedWebflowStepProps,
  WebflowStepProps,
} from './WebflowStep'
import {
  WebflowData,
  SearchLeadsByAddressLead,
  WebflowFunctions,
} from './webflowTypes'
import PlaceholderHouse from '../../images/placeholder-house.webp'

import { DENVER_ADDRESS, GOOGLE_MAPS_API_KEY } from '../../constants'

import OnboardingSplash from '../../images/onboarding-splash.webp'
import OverheadHouse from '../../images/overhead-house.webp'

import { flattenUpdateLeadHelper } from '../../api'

import {
  OnboardingBackgroundProps,
  StaticOnboardingBackground,
  StaticOnboardingBackgroundProps,
} from './BackgroundComponents/OnboardingBackground'
import MapOnboardingBackground from './BackgroundComponents/MapOnboardingBackground'
import PropertyDetailBackground, {
  PropertyDetailBackgroundProps,
} from './BackgroundComponents/PropertyDetailBackground'

import ConditionBackground from './BackgroundComponents/ConditionBackground'
import LoadingBackground, {
  LoadingBackgroundProps,
} from './BackgroundComponents/LoadingBackground'
import {
  HOMEPAGE_PATH,
  OFFER_PAGE_PATH,
  ONBOARDING_PATH,
} from '../../routeConstants'

import generateGoogleMapUrl from '../../utils/googleMapUrl'
import { ProgressType } from './WebflowHeader'
import { getEventTokenUUID } from '../../utils/analytics'

const injectProps =
  (stepName: keyof WebflowData) => (flowData: Partial<WebflowData>) => {
    let headline = ``
    let description = ``

    if (
      stepName === 'dstLeadGen' ||
      (stepName === 'thankYou' && !!flowData.dstLead)
    ) {
      headline = `We'll be in ${
        abbreviationToState[flowData.address?.address?.state!] || `your area`
      } soon.`
      description = `We currently do not take homes in ${
        abbreviationToState[flowData.address?.address?.state!] ||
        `your property's state`
      }. However, we are always looking to expand.`

      if (flowData.homeDetails?.propertyType === 'other') {
        headline = `We'll be in touch.`
        description = `We only take single family homes, multi-family homes, and townhouses.`
      }

      if (
        ['duplex', 'triplex', 'fourplex'].includes(
          flowData?.homeDetails?.propertyType!
        ) &&
        flowData?.homeDetails?.ownsWholeBuilding?.toString() === 'false'
      ) {
        headline = `We'll be in touch.`
        description = `We only take single family homes, multi-family homes, and townhouses that are fully owned.`
      }
    }

    if (headline && description) {
      return {
        flowData,
        stepData: flowData[stepName] || {},
        headline,
        description,
      }
    }
    return {
      flowData,
      stepData: flowData[stepName] || {},
    }
  }

const basicProps = (stepName: keyof WebflowData) => ({
  stepName,
  component: WebflowStep,
  injectProps: injectProps(stepName),
  onCompletion: async (
    flowData: Partial<WebflowData>,
    functions: WebflowFunctions
  ) => {
    const { updateLead } = functions
    await flattenUpdateLeadHelper(
      flowData,
      flowData.leadUuid as string,
      updateLead
    )
    return flowData
  },
})

const getCompletedLeadWithSameContactInfo = (
  flowData: Partial<WebflowData>,
  leads: SearchLeadsByAddressLead[]
) => {
  const { duplicateAddressVerification } = flowData
  const { fullName, email, phoneNumber } = duplicateAddressVerification!
  return leads.find((currentLead: any) => {
    let isDstLead = false
    let error = false
    if (currentLead.slackThreadUrl && currentLead.answers) {
      try {
        const parsedJsonAnswers = JSON.parse(currentLead.answers)
        isDstLead = parsedJsonAnswers.dstLead === true
      } catch (e) {
        error = true
      }
    }

    return (
      !error &&
      !isDstLead &&
      currentLead.fullName &&
      (currentLead.phoneNumber || currentLead.email) &&
      currentLead.fullName === fullName &&
      (currentLead.phoneNumber === phoneNumber ||
        currentLead.email === email) &&
      currentLead.slackThreadUrl
    )
  })
}

// Determines if we should shortcut directly to the offer page and provides the offer page lead UUID to redirect to or null if it does not exist
// TODO: WZ - make this generic so that it isn't specific to these brokers
const shouldRedirectToAgentError = (leads: SearchLeadsByAddressLead[]) =>
  leads?.some((currentLead: SearchLeadsByAddressLead) =>
    [
      'venturerei',
      'atlas',
      'swifthomesolutions',
      'acquiresfr',
      'mavrealty',
    ].includes(
      currentLead?.fullName?.trim().toLowerCase().replace(/\s+/g, '') as string
    )
  )

export const webinarInitialStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('webinarInitialStep'),
  componentProps: {
    stepName: 'webinarInitialStep',
    headline: 'Thank you for attending our webinar.',
    progress: 'address',
    hideBack: true,
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OnboardingSplash,
      applyFilter: true,
      backgroundTransition: true,
    },
    inputConfigs: [
      {
        name: 'webinarOption',
        type: InputType.RadioSelect,
        props: {
          label: `I'm interested in...`,
          row: false,
          options: [
            {
              label: `a complimentary valuation of my rental properties for the 721 exchange.`,
              value: 'submit_address',
            },
            {
              label: `a complimentary consultation with an estate planning attorney.`,
              value: 'schedule_call',
            },
          ],
        },
      },
    ],
  },
  renderIf: async (flowData: Partial<WebflowData>) =>
    flowData.utmSource === '0215estatewebinarform',
  onCompletion: async (flowData: Partial<WebflowData>) => {
    if (flowData.webinarInitialStep?.webinarOption === 'schedule_call') {
      window.location.href =
        'https://docs.google.com/forms/d/e/1FAIpQLSd2dJxN07_cu7L--NtqsBMuG_bwVaFq0zOjzZnY9g3WPkkQKg/viewform'
      // Wait for 15 seconds as we navigate away
      await new Promise((resolve) => setTimeout(resolve, 5000))
    }

    return flowData
  },
}

export const taxPlanInitialStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('taxPlanInitialStep'),
  componentProps: {
    stepName: 'taxPlanInitialStep',
    headline: 'Thank you for attending our webinar.',
    progress: 'address',
    hideBack: true,
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OnboardingSplash,
      applyFilter: true,
      backgroundTransition: true,
    },
    inputConfigs: [
      {
        name: 'taxPlanOption',
        type: InputType.RadioSelect,
        props: {
          label: `I'm interested in...`,
          row: false,
          options: [
            {
              label: `a complimentary valuation of my rental properties for the 721 exchange.`,
              value: 'submit_address',
            },
            {
              label: `a consultation with a tax expert.`,
              value: 'schedule_call',
            },
          ],
        },
      },
    ],
  },
  renderIf: async (flowData: Partial<WebflowData>) =>
    flowData.utmSource === '0326taxplanwebinarform',
  onCompletion: async (flowData: Partial<WebflowData>) => {
    if (flowData.taxPlanInitialStep?.taxPlanOption === 'schedule_call') {
      window.location.href =
        'https://docs.google.com/forms/d/e/1FAIpQLSeQ66ejr_ONe3Lm6QP-ra3BPs91rwcQu8ic-NPXh_5ZALGysA/viewform'
      // Wait for 15 seconds as we navigate away
      await new Promise((resolve) => setTimeout(resolve, 5000))
    }

    return flowData
  },
}

export const initialContactInfoStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('initialContactInfo'),
  onBack: () => {
    window.onbeforeunload = null
    navigate(HOMEPAGE_PATH)
  },
  componentProps: {
    stepName: 'contactInfo',
    headline: `Get your offer in 24 hours.`,
    description: `We'll send you a personalized offer, underwritten by our valuation experts, within 24 hours.`,
    progress: 'profile',
    spacing: 3,
    hideBack: true,
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OnboardingSplash,
      applyFilter: true,
      backgroundTransition: true,
    },
    inputConfigs: [
      {
        name: 'title',
        type: InputType.CustomComponent,
        required: true,
        gridItemProps: {
          display: {
            md: 'none',
          },
        },
        props: {
          component: (
            <Typography
              variant="h1"
              color="moneyGreen.main"
              pb={{ xs: '24px', sm: '40px' }}
            >
              Get your offer in 24 hours.
            </Typography>
          ),
        },
      },
      {
        name: 'fullName',
        type: InputType.Text,
        required: true,
        props: {
          label: 'Full Name',
          size: 'large',
        },
        mobileProps: {
          size: 'small',
        },
      },
      {
        name: 'email',
        type: InputType.Text,
        required: true,
        props: {
          label: 'Email',
          format: 'email',
          size: 'large',
        },
        mobileProps: {
          size: 'small',
        },
      },
      {
        name: 'phoneNumber',
        type: InputType.Text,
        required: true,
        props: {
          label: 'Phone Number',
          format: 'phone',
          size: 'large',
        },
        mobileProps: {
          size: 'small',
        },
      },
    ],
  },
  renderIf: async (flowData: Partial<WebflowData>) => {
    const { duplicateAddressVerification, utmSource } = flowData

    // If we've already tried verification on entering the property address, skip this
    if (
      duplicateAddressVerification?.email ||
      utmSource === '721_exchange_assessment'
    ) {
      return false
    }
    return true
  },
  onCompletion: async (
    flowData: Partial<WebflowData>,
    functions: WebflowFunctions
  ) => {
    // If the lead already exists, then we just update the address
    // Otherwise, we create a new lead
    const { initialContactInfo, source } = flowData
    const { createLead, updateLead } = functions
    const dataToSubmit = {
      contactInfo: initialContactInfo,
      source,
      eventTokenUuid: getEventTokenUUID(),
    }
    const queryParams = localStore?.getItem('queryParams')
    let parsedQueryParams: any = {}
    if (queryParams) {
      parsedQueryParams = JSON.parse(queryParams)
    }

    let leadUuid = flowData?.leadUuid
    if (!flowData.leadUuid) {
      const createLeadResponse = await createLeadHelper(
        dataToSubmit,
        parsedQueryParams,
        createLead
      )
      leadUuid = createLeadResponse.data.createLead.lead.uuid as string
    } else {
      await flattenUpdateLeadHelper(flowData, flowData.leadUuid, updateLead)
    }

    // Clear out the lead uuid in the url
    const newPath = `${ONBOARDING_PATH}?leadUuid=${leadUuid}`
    window.history.replaceState(null, '', newPath)
    const newData = {
      ...flowData,
      leadUuid,
    }

    if (flowData.initialContactInfo) {
      newData.duplicateAddressVerification = {
        ...flowData.initialContactInfo,
      }
    }

    return newData
  },
}

const handleRedirectDuplicateToOfferPage = async (
  flowData: Partial<WebflowData>,
  functions: WebflowFunctions
) => {
  // If the contact info matches the existing lead, we go straight to the valuation page
  const { address, leadUuid } = flowData
  const { searchLeadsByAddress, deleteLead, postSlackMessage } = functions
  const sameAddressLeadsResponse = await searchLeadsByAddress({
    input: { addressUuid: address!.address.addressUuid },
  })
  const existingLead = getCompletedLeadWithSameContactInfo(
    flowData,
    (sameAddressLeadsResponse.data?.searchLeadsByAddress
      ?.leads as SearchLeadsByAddressLead[]) || []
  )
  // If this lead exists, delete the existing lead and navigate to the existing offer page. If we fail to delete it, silently fail.
  if (existingLead && existingLead.uuid !== leadUuid) {
    try {
      const { channel, threadTimestamp } = decomposeSlackUrl(
        existingLead?.slackThreadUrl as string
      )
      await postSlackMessage({
        variables: {
          postSlackMessageInput: {
            channel,
            threadTimestamp,
            text: 'This lead just entered their duplicate address and contact info and visited the valuation page.',
          },
        },
      })
      await deleteLead({
        variables: { deleteLeadInput: { leadUuid: leadUuid as string } },
      })
    } catch (e) {
      Sentry.captureException(
        new Error('Failed to post slack message and delete duplicate lead'),
        {
          extra: {
            leadUuid,
          },
        }
      )
    }

    window.onbeforeunload = null
    window.open(`${OFFER_PAGE_PATH}/${existingLead.uuid}`, '_self')
    if (window.top) {
      window.top.postMessage('Close Modal', '*')
    }

    // This line ensures that the next step doesn't render.
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    await new Promise((resolve) => {})
  }
  return flowData
}

export const enterAddressStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<OnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('address'),
  prerender: async (flowData: Partial<WebflowData>) => {
    // Clear out any existing address here
    const newWebflowData = { ...flowData }
    delete newWebflowData.address
    return newWebflowData
  },
  componentProps: {
    stepName: 'address',
    progress: 'address',
    hideBack: true,
    backgroundComponent: MapOnboardingBackground,
    inputConfigs: [
      {
        name: 'address',
        type: InputType.Address,
        required: true,
        props: {
          label: 'Your rental property address',
          size: 'large',
          placeholder: DENVER_ADDRESS,
        },
        mobileProps: {
          size: 'small',
        },
      },
    ],
  },
  onCompletion: async (
    flowData: Partial<WebflowData>,
    functions: WebflowFunctions
  ) => {
    // If the lead already exists, then we just update the address
    // Otherwise, we create a new lead
    if (flowData.leadUuid && flowData.address?.address?.addressUuid) {
      const { updateLead } = functions
      await flattenUpdateLeadHelper(flowData, flowData.leadUuid, updateLead)

      const newData = { ...flowData }
      // If it's not a valid market, this is a DST lead.
      if (!isValidMarket(flowData.address?.address?.state)) {
        newData.dstLead = true
      }

      return newData
    } else {
      const { getLead, createLead, updateLead } = functions

      const queryParams = localStore?.getItem('queryParams')
      let parsedQueryParams: any = {}
      if (queryParams) {
        parsedQueryParams = JSON.parse(queryParams)
      }

      // If lead is coming from a contact info submission
      const dataToSubmit = {
        addressData: flowData?.address?.address,
        source: 'onboarding',
        fullName: parsedQueryParams.fullName,
        email: parsedQueryParams.email,
        phoneNumber: parsedQueryParams.phoneNumber,
        eventTokenUuid: getEventTokenUUID(),
        transactionType: Core_TransactionType.TransactionTypeSingle_721,
      }

      let newLeadUuid = flowData.leadUuid

      if (!flowData.leadUuid) {
        const createLeadResponse = await createLeadHelper(
          dataToSubmit,
          parsedQueryParams,
          createLead
        )
        newLeadUuid = createLeadResponse.data.createLead.lead.uuid
        // Clear out the lead uuid in the url
        const newPath = `${ONBOARDING_PATH}/?leadUuid=${newLeadUuid}`
        window.history.replaceState(null, '', newPath)
      } else {
        await flattenUpdateLeadHelper(flowData, flowData.leadUuid, updateLead)
      }

      const getLeadResponse = await getLead({
        input: { leadUuid: newLeadUuid as string },
      })

      let newData: Partial<WebflowData> = {}
      if (getLeadResponse?.data?.lead?.lead?.address?.street) {
        const { lead } = getLeadResponse.data.lead
        if (lead.address) {
          const {
            street,
            formattedStreet,
            formattedAddress,
            streetNumber,
            unit,
            city,
            state,
            zipcode,
            latitude,
            longitude,
          } = lead.address

          const { addressUuid } = lead

          newData = {
            ...flowData,
            leadUuid: newLeadUuid,
            address: {
              address: {
                street: street as string,
                streetAddress: formattedStreet as string,
                streetNumber: streetNumber as string,
                unit: unit as string,
                unitNumber: unit as string,
                city: city as string,
                state: state as string,
                zipcode: zipcode as string,
                lat: latitude as number,
                lng: longitude as number,
                addressUuid: addressUuid as string,
                formattedAddress: formattedAddress as string,
              },
            },
          }
        }
      }

      // If it's not a valid market, this is a DST lead.
      if (!isValidMarket(newData.address?.address?.state!)) {
        newData.dstLead = true
      }

      // If they came through the initial contact info flow, we can redirect for
      // a duplicate lead
      if (flowData.initialContactInfo) {
        return handleRedirectDuplicateToOfferPage(newData, functions)
      }

      return newData
    }
  },
}

export const isExistingAddressWithCompletedNonDstLead = (
  leads: SearchLeadsByAddressLead[]
) =>
  !!leads?.some((currentLead: SearchLeadsByAddressLead) => {
    try {
      let isDstLead = false
      if (currentLead.answers) {
        const parsedJsonAnswers = JSON.parse(currentLead.answers)
        isDstLead = parsedJsonAnswers.dstLead === true
      }
      return (
        !isDstLead &&
        currentLead.fullName &&
        (currentLead.phoneNumber || currentLead.email) &&
        currentLead.slackThreadUrl
      )
    } catch (e) {
      Sentry.captureException(
        new Error('Failed to parse malformed answer for lead'),
        {
          extra: {
            leadUuid: currentLead.uuid,
          },
        }
      )
    }
    return false
  })

export const duplicateAddressVerificationStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('duplicateAddressVerification'),
  onBack: () => {
    window.onbeforeunload = null
    navigate(HOMEPAGE_PATH)
  },
  componentProps: {
    stepName: 'contactInfo',
    headline: `Get your offer in 24 hours.`,
    description: `We'll send you a personalized offer, underwritten by our valuation experts, within 24 hours.`,
    progress: 'address',
    spacing: 3,
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OnboardingSplash,
      applyFilter: true,
    },
    inputConfigs: [
      {
        name: 'title',
        type: InputType.CustomComponent,
        required: true,
        gridItemProps: {
          display: {
            md: 'none',
          },
        },
        props: {
          component: (
            <Typography
              variant="h1"
              color="moneyGreen.main"
              pb={{ xs: '24px', sm: '40px' }}
            >
              Get your offer in 24 hours
            </Typography>
          ),
        },
      },
      {
        name: 'fullName',
        type: InputType.Text,
        required: true,
        props: {
          label: 'Full Name',
          size: 'large',
        },
        mobileProps: {
          size: 'small',
        },
      },
      {
        name: 'email',
        type: InputType.Text,
        required: true,
        props: {
          label: 'Email',
          format: 'email',
          size: 'large',
        },
        mobileProps: {
          size: 'small',
        },
      },
      {
        name: 'phoneNumber',
        type: InputType.Text,
        required: true,
        props: {
          label: 'Phone Number',
          format: 'phone',
          size: 'large',
        },
        mobileProps: {
          size: 'small',
        },
      },
    ],
  },
  renderIf: async (
    flowData: Partial<WebflowData>,
    functions: WebflowFunctions
  ) => {
    // If there's another lead with the same address, we need to
    // render this step
    const { address, duplicateAddressVerification, utmSource } = flowData

    // If we've already tried verification on entering the property address, skip this
    if (
      duplicateAddressVerification?.fullName ||
      utmSource === '721_exchange_assessment'
    ) {
      return false
    }
    const { searchLeadsByAddress } = functions
    const sameAddressLeadsResponse = await searchLeadsByAddress({
      input: { addressUuid: address?.address?.addressUuid as string },
    })
    return (
      isExistingAddressWithCompletedNonDstLead(
        (sameAddressLeadsResponse?.data?.searchLeadsByAddress
          ?.leads as SearchLeadsByAddressLead[]) || []
      ) &&
      !shouldRedirectToAgentError(
        (sameAddressLeadsResponse?.data?.searchLeadsByAddress
          ?.leads as SearchLeadsByAddressLead[]) || []
      )
    )
  },
  onCompletion: async (
    flowData: Partial<WebflowData>,
    functions: WebflowFunctions
  ) => handleRedirectDuplicateToOfferPage(flowData, functions),
}

export const earlyExitContactAgentStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('earlyExitContactAgent'),
  componentProps: {
    stepName: 'earlyExitContactAgent',
    hideBack: true,
    ctaText: 'Finish',
    progress: 'complete',
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OnboardingSplash,
      applyFilter: true,
    },
    inputConfigs: [
      {
        name: 'earlyExitContactAgentDescription',
        type: InputType.CustomComponent,
        props: {
          component: (
            <Typography variant="h4">
              This address appears to be associated with a broker. Please
              contact your broker for more details or contact us at{' '}
              <TrackedLink onClick={() => navigate(`mailto:${FLOCK_EMAIL}`)}>
                info@flockhomes.com
              </TrackedLink>{' '}
              if you think this is a mistake.
            </Typography>
          ),
        },
      },
    ],
  },
  renderIf: async (
    flowData: Partial<WebflowData>,
    functions: WebflowFunctions
  ) => {
    // If there's another lead with the same address that is one of the specified
    // brokers, we show an error message.
    const { address } = flowData
    const { searchLeadsByAddress } = functions
    const sameAddressLeadsResponse = await searchLeadsByAddress({
      input: { addressUuid: address!.address.addressUuid },
    })
    return shouldRedirectToAgentError(
      (sameAddressLeadsResponse?.data?.searchLeadsByAddress
        ?.leads as SearchLeadsByAddressLead[]) || []
    )
  },
  prerender: async (flowData: Partial<WebflowData>) => {
    const { leadUuid } = flowData
    Sentry.captureException(
      new Error(
        'Attempted access to address that already has an offer page from a broker'
      ),
      {
        extra: {
          leadUuid,
        },
      }
    )
    return flowData
  },
  onCompletion: async (flowData: Partial<WebflowData>) => {
    window.onbeforeunload = null
    navigate(HOMEPAGE_PATH)
    return flowData
  },
}

export const homeDetailsStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<PropertyDetailBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('homeDetails'),
  prerender: async (
    flowData: Partial<WebflowData>,
    functions: WebflowFunctions
  ) => {
    // Preloads the google street view image url
    const { formattedAddress } = flowData.address?.address!
    let streetViewImageUrl = PlaceholderHouse
    const streetviewMetadataUrl = generateGoogleMapUrl({
      type: 'streetview/metadata',
      location: formattedAddress,
      key: GOOGLE_MAPS_API_KEY,
    })

    try {
      const response = await fetch(streetviewMetadataUrl)
      const resultData = await response.json()
      if (resultData.status === 'OK') {
        streetViewImageUrl = generateGoogleMapUrl({
          type: 'streetview',
          location: formattedAddress,
          size: '800x650',
          key: GOOGLE_MAPS_API_KEY,
          fov: 50,
        })
      }
    } catch (e) {
      console.error('Failed to find street view image')
    }

    if (!flowData.prefillData) {
      try {
        const { getHomeDetails } = functions

        const { streetNumber, streetAddress, city, state, zipcode } =
          flowData.address!.address

        const addressInput = {
          streetAddress: `${streetNumber} ${streetAddress}`,
          city,
          state,
          zip: zipcode,
        }

        const { data: prefillResponse } = await getHomeDetails({
          homeDetailsInput: addressInput,
        })

        let homeDetailsData: any = {}
        const prefillData: any = {}
        let basementFormatted = 'none'
        if (prefillResponse) {
          homeDetailsData = prefillResponse?.homeDetails?.homeDetails
          const {
            squareFootage,
            bedrooms: bedCount,
            fullBathrooms: bathCount,
            halfBathrooms: halfBathCount,
            basement,
            yearBuilt,
            buildingQualityScore,
            buildingConditionScore,
          } = prefillResponse.homeDetails?.homeDetails as Core_HomeDetails

          prefillData.prefillSquareFootage = squareFootage
          prefillData.prefillBedCount = bedCount
          prefillData.prefillBathCount = bathCount
          prefillData.prefillHalfBathCount = halfBathCount
          prefillData.prefillBasement = basement
          prefillData.prefillYearBuilt = yearBuilt
          prefillData.prefillBuildingQualityScore = buildingQualityScore
          prefillData.prefillBuildingConditionScore = buildingConditionScore

          const basementResp = basement?.toLowerCase() || 'none'
          if (basementResp.includes('partial')) {
            basementFormatted = 'partiallyFinished'
          } else if (basementResp.includes('no basement')) {
            basementFormatted = 'none'
          } else if (basementResp.includes('unfinished')) {
            basementFormatted = 'unfinished'
          } else {
            basementFormatted = 'fullyFinished'
          }
        }
        const newWebflowData = {
          ...flowData,
          homeDetails: {
            ...homeDetailsData,
            bedCount: homeDetailsData.bedrooms || '',
            bathCount:
              homeDetailsData.fullBathrooms + homeDetailsData.halfBathrooms ||
              '',
            squareFootage: homeDetailsData.squareFootage || '',
            basement: basementFormatted,
          },
          prefillData,
          streetViewImageUrl,
        }
        return newWebflowData
      } catch (e) {
        return {
          ...flowData,
          streetViewImageUrl,
        }
      }
    } else {
      return {
        ...flowData,
        streetViewImageUrl,
      }
    }
  },
  injectProps: (flowData: Partial<WebflowData>) => {
    const { streetViewImageUrl } = flowData

    return {
      flowData,
      stepData: flowData.homeDetails || {},
      backgroundComponentProps: {
        streetViewImageUrl,
        backgroundTransition: true,
      },
    }
  },
  onCompletion: async (
    flowData: Partial<WebflowData>,
    functions: WebflowFunctions
  ) => {
    const { homeDetails } = flowData
    const dstLead =
      !isValidMarket(flowData.address!.address.state) ||
      ['other'].includes(homeDetails?.propertyType as string) ||
      (['duplex', 'triplex', 'fourplex'].includes(
        homeDetails?.propertyType as string
      ) &&
        homeDetails?.ownsWholeBuilding?.toString() === 'false')
    const newData = {
      ...flowData,
      dstLead,
    }
    const { updateLead } = functions
    await flattenUpdateLeadHelper(
      newData,
      flowData.leadUuid as string,
      updateLead
    )
    return newData
  },
  componentProps: {
    stepName: 'homeDetails',
    progress: 'address',
    headline: 'Nice house!',
    description: `If you don't know the specifics, give us your best guesses, and our professional underwriters will cross reference them with our database.`,
    prefillDescription:
      'We collect information from property tax records and the MLS (Multiple Listing Services), but we want to ensure your details are right.',
    spacing: 3,
    backgroundComponent: PropertyDetailBackground,
    preprocessInputConfigs: (
      inputConfigs: AnyInputConfig[],
      flowData: Partial<WebflowData>
    ) =>
      // We use this to inject the address into the question
      [
        {
          name: 'homeDetailsAddressQuestion',
          type: InputType.CustomComponent,
          props: {
            component: (
              <Typography variant="h4">
                {flowData.address?.address?.formattedAddress} is a...
              </Typography>
            ),
          },
        },
        ...inputConfigs,
      ],
    inputConfigs: [
      {
        name: 'bedCount',
        type: InputType.Text,
        required: true,
        gridItemProps: {
          xs: 12,
          sm: 6,
        },
        props: {
          'aria-label': 'Bed Count',
          format: 'number',
          placeholder: '0',
          sx: {
            maxWidth: '80px',
          },
          centered: true,
        },
        mobileProps: {
          sx: {
            maxWidth: 'unset',
            width: '154px',
          },
        },
        inputDecoratorProps: {
          icon: BedroomIcon,
          postfix: 'bed',
        },
      },
      {
        name: 'bathCount',
        type: InputType.Text,
        required: true,
        gridItemProps: {
          xs: 12,
          sm: 6,
          display: {
            xs: 'block',
            sm: 'flex',
          },
          justifyContent: 'flex-end',
        },
        props: {
          'aria-label': 'Bath Count',
          format: 'number',
          placeholder: '0',
          sx: {
            maxWidth: '80px',
          },
          centered: true,
        },
        mobileProps: {
          sx: {
            maxWidth: 'unset',
            width: '154px',
          },
        },
        inputDecoratorProps: {
          icon: BathroomIcon,
          postfix: 'bath',
        },
      },
      {
        name: 'squareFootage',
        type: InputType.Text,
        required: true,
        props: {
          'aria-label': 'Square Footage',
          format: 'number',
          placeholder: '0',
          centered: true,
          sx: {
            maxWidth: '176px',
          },
        },
        mobileProps: {
          sx: {
            maxWidth: 'unset',
            width: '154px',
          },
        },
        inputDecoratorProps: {
          icon: DimensionsIcon,
          postfix: 'sq ft',
          postfixAdornment: '(above ground)',
        },
        inputDecoratorMobileProps: {
          postfix: 'sq ft',
          postfixAdornment: '',
        },
      },
      {
        name: 'propertyType',
        type: InputType.Dropdown,
        required: true,
        inputDecoratorProps: {
          icon: HouseOutlinedIcon,
        },
        props: {
          'aria-label': 'Property Type',
          options: [
            {
              label: 'single-family home',
              value: 'singlefamily',
            },
            {
              label: 'duplex',
              value: 'duplex',
            },
            {
              label: 'triplex',
              value: 'triplex',
            },
            {
              label: 'fourplex',
              value: 'fourplex',
            },
            {
              label: 'attached townhouse',
              value: 'townhouse',
            },
            {
              label: 'condo',
              value: 'condo',
            },
            {
              label: 'other',
              value: 'other',
            },
          ],
        },
      },
      {
        name: 'ownsWholeBuilding',
        type: InputType.RadioSelect,
        required: true,
        renderIf: (watchedFields: any) =>
          ['duplex', 'triplex', 'fourplex'].includes(
            watchedFields.propertyType
          ),
        props: {
          label: 'Do you own the whole building?',
          row: true,
          options: [
            {
              label: 'Yes',
              value: 'true',
            },
            {
              label: 'No',
              value: 'false',
            },
          ],
        },
      },
      {
        name: 'unitNumber',
        type: InputType.Text,
        renderIf: (watchedFields: any) =>
          ['townhouse', 'condo', 'other'].includes(watchedFields.propertyType),
        props: {
          'aria-label': 'Unit Number',
          placeholder: 'Unit # (if applicable)',
        },
        inputDecoratorProps: {
          icon: HouseFilledIcon,
        },
      },
    ],
  },
}

const unitToString: { [key: string]: any } = {
  1: 'first',
  2: 'second',
  3: 'third',
  4: 'fourth',
}

const getUnitDetails: (num: number) => AnyInputConfig[] = (num: number) => [
  {
    name: `unit${num}Question`,
    type: InputType.CustomComponent,
    props: {
      component: (
        <Typography variant="h4">The {unitToString[num]} unit is...</Typography>
      ),
    },
  },
  {
    name: `unit${num}Name`,
    type: InputType.Text,
    required: true,
    defaultValue: `Unit ${num}`,
    props: {
      type: 'text',
      placeholder: `Unit number`,
      centered: true,
      sx: {
        maxWidth: '176px',
      },
    },
    mobileProps: {
      sx: {
        maxWidth: 'unset',
        width: '154px',
      },
    },
    inputDecoratorProps: {
      icon: HouseOutlinedIcon,
    },
  },
  {
    name: `unit${num}BedCount`,
    type: InputType.Text,

    required: true,
    gridItemProps: {
      xs: 12,
      sm: 6,
    },
    props: {
      'aria-label': 'Bed Count',
      format: 'number',
      placeholder: '0',
      sx: {
        maxWidth: '80px',
      },
      centered: true,
    },
    mobileProps: {
      sx: {
        maxWidth: 'unset',
        width: '154px',
      },
    },
    inputDecoratorProps: {
      icon: BedroomIcon,
      postfix: 'bed',
    },
  },
  {
    name: `unit${num}BathCount`,
    type: InputType.Text,

    required: true,
    gridItemProps: {
      xs: 12,
      sm: 6,
      display: {
        xs: 'block',
        sm: 'flex',
      },
      justifyContent: 'flex-end',
    },
    props: {
      'aria-label': 'Bath Count',
      format: 'number',
      placeholder: '0',
      sx: {
        maxWidth: '80px',
      },
      centered: true,
    },
    mobileProps: {
      sx: {
        maxWidth: 'unset',
        width: '154px',
      },
    },
    inputDecoratorProps: {
      icon: BathroomIcon,
      postfix: 'bath',
    },
  },
  {
    name: `unit${num}SquareFootage`,
    type: InputType.Text,

    required: true,
    props: {
      'aria-label': 'Square Footage',
      format: 'number',
      placeholder: '0',
      centered: true,
      sx: {
        maxWidth: '176px',
      },
    },
    mobileProps: {
      sx: {
        maxWidth: 'unset',
        width: '154px',
      },
    },
    inputDecoratorProps: {
      icon: DimensionsIcon,
      postfix: 'sq ft',
      postfixAdornment: '(above ground)',
    },
    inputDecoratorMobileProps: {
      postfix: 'sq ft',
      postfixAdornment: '',
    },
  },
  {
    name: `unit${num}HasTenant`,
    type: InputType.RadioSelect,
    required: true,
    props: {
      label: 'Does this property have a tenant?',
      row: true,
      options: [
        {
          label: 'No',
          value: 'false',
        },
        {
          label: 'Yes',
          value: 'true',
        },
      ],
      mobileRowOverride: true,
    },
  },
  {
    name: `unit${num}LeaseType`,
    type: InputType.RadioSelect,
    renderIf: (watchedFields: any) =>
      watchedFields[`unit${num}HasTenant`] === 'true',
    props: {
      row: true,
      options: [
        {
          label: 'Month-to-month',
          value: 'mtm',
        },
        {
          label: 'Long-term',
          value: 'long_term',
        },
      ],
    },
  },
  {
    name: `unit${num}RentAmount`,
    type: InputType.Text,
    required: true,
    renderIf: (watchedFields: any) =>
      watchedFields[`unit${num}HasTenant`] === 'true',
    props: {
      format: 'dollars',
      placeholder: '$0',
      centered: true,
      sx: {
        width: '180px',
      },
    },
    inputDecoratorProps: {
      postfix: 'rent / month',
    },
  },
  {
    name: `unit${num}LeaseEnd`,
    type: InputType.DatePicker,
    required: true,
    renderIf: (watchedFields: any) =>
      watchedFields[`unit${num}LeaseType`] !== 'mtm' &&
      watchedFields[`unit${num}HasTenant`] === 'true',
    props: {
      placeholder: 'MM/DD/YYYY',
      disablePast: true,
      sx: {
        width: '180px',
      },
      centered: true,
    },
    inputDecoratorProps: {
      postfix: 'lease end date',
    },
  },
]

const generateUnitConfig = (num: 1 | 2 | 3 | 4) => ({
  ...basicProps(`unit${num}Details`),
  injectProps: (flowData: Partial<WebflowData>) => {
    const { streetViewImageUrl } = flowData

    return {
      flowData,
      stepData: flowData[`unit${num}Details` as keyof WebflowData] || {},
      backgroundComponentProps: {
        streetViewImageUrl,
      },
    }
  },
  componentProps: {
    stepName: `unit${num}Details`,
    headline: 'Nice house!',
    description: `If you don't know the details, give us your best guess, and our professional underwriters will cross reference it with our data.`,
    spacing: 3,
    progress: 'address' as ProgressType,
    backgroundComponent: PropertyDetailBackground,
    inputConfigs: getUnitDetails(num),
    addButtonPadding: true,
  },
})

export const unit1DetailsStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<PropertyDetailBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...generateUnitConfig(1),
  renderIf: async (flowData: Partial<WebflowData>) =>
    ['duplex', 'triplex', 'fourplex'].includes(
      flowData.homeDetails!.propertyType as string
    ) && flowData.homeDetails!.ownsWholeBuilding === 'true',
}

export const unit2DetailsStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<PropertyDetailBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...generateUnitConfig(2),
  renderIf: async (flowData: Partial<WebflowData>) =>
    ['duplex', 'triplex', 'fourplex'].includes(
      flowData.homeDetails!.propertyType
    ) && flowData.homeDetails!.ownsWholeBuilding === 'true',
}

export const unit3DetailsStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<PropertyDetailBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...generateUnitConfig(3),
  renderIf: async (flowData: Partial<WebflowData>) =>
    ['triplex', 'fourplex'].includes(flowData.homeDetails!.propertyType) &&
    flowData.homeDetails!.ownsWholeBuilding === 'true',
}

export const unit4DetailsStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<PropertyDetailBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...generateUnitConfig(4),
  renderIf: async (flowData: Partial<WebflowData>) =>
    ['fourplex'].includes(flowData.homeDetails!.propertyType) &&
    flowData.homeDetails!.ownsWholeBuilding === 'true',
}

export const propertyConditionStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<OnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('propertyCondition'),
  componentProps: {
    stepName: 'propertyCondition',
    progress: 'condition',
    backgroundComponent: ConditionBackground,
    preprocessInputConfigs: (
      inputConfigs: AnyInputConfig[],
      flowData: Partial<WebflowData>
    ) =>
      // We use this to inject the address into the question
      [
        {
          name: 'propertyConditionQuestion',
          type: InputType.CustomComponent,
          props: {
            component: (
              <Typography variant="h4">
                The condition of {flowData.address?.address?.streetNumber}{' '}
                {flowData.address?.address?.streetAddress} is...
              </Typography>
            ),
          },
        },
        ...inputConfigs,
      ],
    inputConfigs: [
      {
        name: 'propertyCondition',
        type: InputType.RadioSelect,
        required: true,
        props: {
          options: [
            {
              label: 'Near perfect',
              subLabel:
                'Like-new home, updated and move-in ready. Everything from flooring to paint is in excellent condition.',
              value: '5 - pristine',
            },
            {
              label: 'Normal wear and tear',
              subLabel:
                'Fully functional and livable with no immediate mechanical work needed. Cosmetic updates would improve the property but not immediately necessary.',
              value: '4 - great',
            },
            {
              label: 'Needs minor repairs',
              subLabel:
                'Livable but work is needed, such as a full interior paint job and new flooring. Appliances working today but dated and will need replacing in the near future.',
              value: '3 - average',
            },
            {
              label: 'Needs major work',
              subLabel:
                'Cosmetically dated, but no immediate health or safety issues. Roof, HVAC, water heater, or appliances are nearing the end of their useful life.',
              value: '2 - below average',
            },
            {
              label: 'Not habitable',
              subLabel:
                'Home is in cosmetic and mechanical disrepair and uninhabitable.',
              value: '1 - requires complete renovation',
            },
          ],
        },
      },
    ],
  },
}

export const additionalInfoStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('additionalInfo'),
  componentProps: {
    stepName: 'additionalInfo',
    progress: 'financials',
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OverheadHouse,
      hideTabletBackground: true,
    },
    inputConfigs: [
      {
        name: 'additionalInfo',
        type: InputType.Text,
        labelText: `Is there anything else you'd like to tell us about the property?`,
        props: {
          multiline: true,
          rows: 4,
          placeholder:
            'More info about recent or needed updates? This information will allow us to give you a more accurate offer.',
        },
      },
    ],
  },
}

export const hasMortgageStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('hasMortgage'),
  componentProps: {
    stepName: 'hasMortgage',
    progress: 'financials',
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OverheadHouse,
      hideTabletBackground: true,
      backgroundTransition: true,
    },
    inputConfigs: [
      {
        name: 'hasMortgage',
        type: InputType.RadioSelect,
        required: true,
        props: {
          label: 'Do you have a mortgage on this property?',
          row: true,
          options: [
            {
              label: 'No',
              value: 'no',
            },
            {
              label: 'Yes',
              value: 'yes',
            },
          ],
        },
      },
    ],
  },
}

export const mortgageAmountStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('mortgageAmount'),
  renderIf: async (flowData: Partial<WebflowData>) =>
    flowData.hasMortgage?.hasMortgage === 'yes',
  componentProps: {
    stepName: 'mortgageAmount',
    progress: 'financials',
    spacing: 4,
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OverheadHouse,
      hideTabletBackground: true,
    },
    inputConfigs: [
      {
        name: 'mortgageAmount',
        type: InputType.Text,
        required: true,
        labelText: 'What is the outstanding mortgage balance?',
        labelOptionalText: '(An estimate is okay for now.)',
        props: {
          placeholder: '$0',
          format: 'dollars',
        },
      },
    ],
  },
}

export const hasTenantStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('hasTenant'),
  renderIf: async (flowData: Partial<WebflowData>) => {
    const { dstLead } = flowData
    return (
      !['duplex', 'triplex', 'fourplex'].includes(
        flowData.homeDetails!.propertyType
      ) && !dstLead
    )
  },
  componentProps: {
    stepName: 'hasTenant',
    progress: 'financials',
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OverheadHouse,
      hideTabletBackground: true,
    },
    inputConfigs: [
      {
        name: 'hasTenant',
        type: InputType.RadioSelect,
        required: true,
        props: {
          label: 'Does this property have a tenant?',
          row: true,
          options: [
            {
              label: 'No',
              value: 'false',
            },
            {
              label: 'Yes',
              value: 'true',
            },
          ],
        },
      },
    ],
  },
}
export const leaseDetailsStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('leaseDetails'),
  renderIf: async (flowData: Partial<WebflowData>) =>
    flowData.hasTenant?.hasTenant === 'true',
  componentProps: {
    stepName: 'leaseDetails',
    progress: 'financials',
    spacing: 3,
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OverheadHouse,
      hideTabletBackground: true,
    },
    inputConfigs: [
      {
        name: 'leaseType',
        type: InputType.RadioSelect,
        props: {
          label: 'The lease is...',
          row: true,
          options: [
            {
              label: 'Month-to-month',
              value: 'mtm',
            },
            {
              label: 'Long-term',
              value: 'long_term',
            },
          ],
        },
      },
      {
        name: 'rentAmount',
        type: InputType.Text,
        required: true,
        labelText: 'The rent is',
        props: {
          format: 'dollars',
          placeholder: '$0',
          centered: true,
          sx: {
            width: '180px',
          },
        },
        inputDecoratorProps: {
          postfix: 'rent / month',
        },
      },
      {
        name: 'leaseEnd',
        type: InputType.DatePicker,
        required: true,
        labelText: 'The lease ends',
        renderIf: (watchedFields: any) => watchedFields.leaseType !== 'mtm',
        props: {
          placeholder: 'MM/DD/YYYY',
          disablePast: true,
          sx: {
            width: '180px',
          },
          centered: true,
        },
      },
    ],
  },
}

export const contactInfoStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('contactInfo'),
  renderIf: async (flowData: Partial<WebflowData>) =>
    !flowData.initialContactInfo &&
    !flowData.duplicateAddressVerification &&
    !flowData.dstLead,
  componentProps: {
    stepName: 'contactInfo',
    headline: `Get your offer in 24 hours.`,
    description: `We'll send you a personalized offer, underwritten by our valuation experts, within 24 hours.`,
    progress: 'profile',
    spacing: 3,
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OnboardingSplash,
      applyFilter: true,
      backgroundTransition: true,
    },
    inputConfigs: [
      {
        name: 'title',
        type: InputType.CustomComponent,
        required: true,
        gridItemProps: {
          display: {
            md: 'none',
          },
        },
        props: {
          component: (
            <Typography
              variant="h1"
              color="moneyGreen.main"
              pb={{ xs: '24px', sm: '40px' }}
            >
              Get your offer in 24 hours.
            </Typography>
          ),
        },
      },
      {
        name: 'fullName',
        type: InputType.Text,
        required: true,
        props: {
          label: 'Full Name',
          size: 'large',
        },
        mobileProps: {
          size: 'small',
        },
      },
      {
        name: 'email',
        type: InputType.Text,
        required: true,
        props: {
          label: 'Email',
          format: 'email',
          size: 'large',
        },
        mobileProps: {
          size: 'small',
        },
      },
      {
        name: 'phoneNumber',
        type: InputType.Text,
        required: false,
        props: {
          label: 'Phone Number (optional)',
          format: 'phone',
          size: 'large',
        },
        mobileProps: {
          size: 'small',
        },
      },
    ],
  },
}

export const selfReportedValueStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('selfReportedValue'),
  componentProps: {
    stepName: 'mortgageAmount',
    progress: 'financials',
    spacing: 4,
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OverheadHouse,
      hideTabletBackground: true,
    },
    inputConfigs: [
      {
        name: 'selfReportedValue',
        type: InputType.Text,
        labelText: 'What do you estimate to be the value of your property?',
        labelOptionalText: '(optional)',
        props: {
          placeholder: '$0',
          format: 'dollars',
        },
      },
    ],
  },
}

export const attributionStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<LoadingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('attribution'),
  componentProps: {
    stepName: 'attribution',
    progress: 'complete',
    ctaText: 'Submit',
    backgroundComponent: LoadingBackground,
    backgroundComponentProps: {
      backgroundTransition: true,
      hideTabletBackground: true,
    },
    inputConfigs: [
      {
        name: 'attribution',
        type: InputType.RadioSelect,
        required: true,
        props: {
          sx: { gap: '0px' },
          label: `While we’re generating your preliminary offer, do you mind sharing how you heard about Flock?`,
          options: [
            {
              label: 'Google Search Result',
              value: 'internetSearch',
            },
            {
              label: 'Google Ad',
              value: 'googleAd',
            },
            {
              label: 'Newspaper Ad',
              value: 'newspaperAd',
            },
            {
              label: 'Postcard or Letter',
              value: 'directMail',
            },
            {
              label: 'BiggerPockets Newsletter',
              value: 'biggerPocketsNewsletter',
            },
            {
              label: 'Email',
              value: 'email',
            },
            {
              label: 'Facebook or Instagram Ad',
              value: 'facebookAd',
            },
            {
              label: 'Realtor or Broker',
              value: 'realtor',
            },
            {
              label: 'Other',
              value: 'other',
            },
          ],
        },
      },
      {
        name: 'attributionDetail',
        type: InputType.Text,
        required: true,
        renderIf: (watchedFields: any) => watchedFields.attribution === 'other',
        props: {
          placeholder: 'Please specify how you heard about Flock.',
        },
      },
    ],
  },
}

export const dstAttributionStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('attribution'),
  renderIf: async (flowData: Partial<WebflowData>) => !!flowData.dstLead,
  componentProps: {
    stepName: 'attribution',
    progress: 'complete',
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OnboardingSplash,
      applyFilter: true,
    },
    inputConfigs: [
      {
        name: 'attribution',
        type: InputType.RadioSelect,
        required: true,

        props: {
          label: `How did you hear about Flock?`,
          options: [
            {
              label: 'Google search result',
              value: 'internetSearch',
            },
            {
              label: 'Email',
              value: 'email',
            },
            {
              label: 'Word of mouth',
              value: 'wordOfMouth',
            },
            {
              label: 'Postcard or letter',
              value: 'directMail',
            },
            {
              label: 'Facebook or Instagram ad',
              value: 'facebookAd',
            },
            {
              label: 'Google ad',
              value: 'googleAd',
            },
            {
              label: 'Realtor or broker',
              value: 'realtor',
            },
            {
              label: 'Other',
              value: 'other',
            },
          ],
        },
      },
      {
        name: 'attributionDetail',
        type: InputType.Text,
        required: true,
        renderIf: (watchedFields: any) => watchedFields.attribution === 'other',
        props: {
          placeholder: 'Please specify how you heard about Flock.',
        },
      },
    ],
  },
}

export const thankYouStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<LoadingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('thankYou'),
  componentProps: {
    stepName: 'thankYou',
    progress: 'complete',
    backgroundComponent: LoadingBackground,
    backgroundComponentProps: {
      hideTabletBackground: true,
      ready: true,
    },
    autoProgressTime: 2000,
    inputConfigs: [
      {
        name: 'thankYou',
        type: InputType.CustomComponent,
        props: {
          component: (
            <Box display="flex" flexDirection="column" gap="16px">
              <Typography variant="h1" color="moneyGreen.main">
                Thank you!
              </Typography>
              <Typography variant="h4">
                You will be automatically redirected to your offer page shortly.
              </Typography>
              <CircularProgress />
            </Box>
          ),
        },
      },
    ],
  },
  onCompletion: async (
    flowData: Partial<WebflowData>,
    functions: WebflowFunctions
  ) => {
    const { updateLead, completedLeadSideEffects, trackOnboardingCompletion } =
      functions
    const { leadUuid } = flowData

    if (localStore) {
      const completedLeadParams = {
        streetAddress: flowData.address?.address?.streetAddress,
        streetNumber: flowData.address?.address?.streetNumber,
        leadUuid,
      }
      localStore?.setItem(
        'completedLeadParams',
        JSON.stringify(completedLeadParams)
      )
    }
    await flattenUpdateLeadHelper(flowData, flowData.leadUuid!, updateLead)
    await completedLeadSideEffects({
      variables: {
        completedLeadSideEffectsInput: {
          leadUuid: leadUuid!,
          sendWelcomeEmail: true,
        },
      },
    })
    trackOnboardingCompletion()
    window.onbeforeunload = null
    window.open(`${OFFER_PAGE_PATH}/${leadUuid}`, '_self')
    if (window.top) {
      window.top.postMessage('Close Modal', '*')
    }
    // This line ensures that the next step doesn't render.
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    await new Promise((resolve) => {})

    return flowData
  },
}

export const dstLeadGenStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('dstLeadGen'),
  renderIf: async (flowData: Partial<WebflowData>) => !!flowData.dstLead,
  prerender: async (flowData: Partial<WebflowData>) => ({
    ...flowData,
    dstLeadGen: {
      ...flowData.contactInfo!,
    },
  }),
  componentProps: {
    stepName: 'dstLeadGen',
    progress: 'profile',
    spacing: 3,
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OnboardingSplash,
      applyFilter: true,
    },
    ctaText: 'Submit',
    inputConfigs: [
      {
        name: 'title',
        type: InputType.CustomComponent,
        required: true,
        gridItemProps: {
          display: {
            md: 'none',
          },
        },
        props: {
          component: (
            <Typography
              variant="h4"
              color="moneyGreen.main"
              pb={{ xs: '24px', sm: '40px' }}
            >
              We currently do not take homes in your property&apos;s state.
              However, we are always looking to expand. Leave your contact info
              and we&apos;ll be in touch soon!
            </Typography>
          ),
        },
      },
      {
        name: 'fullName',
        type: InputType.Text,
        required: true,
        props: {
          label: 'Full Name',
          size: 'large',
        },
        mobileProps: {
          size: 'small',
        },
      },
      {
        name: 'email',
        type: InputType.Text,
        required: true,
        props: {
          label: 'Email',
          format: 'email',
          size: 'large',
        },
        mobileProps: {
          size: 'small',
        },
      },
      {
        name: 'phoneNumber',
        type: InputType.Text,
        required: false,
        props: {
          label: 'Phone Number (optional)',
          format: 'phone',
          size: 'large',
        },
        mobileProps: {
          size: 'small',
        },
      },
    ],
  },
  onCompletion: async (
    flowData: Partial<WebflowData>,
    functions: WebflowFunctions
  ) => {
    const { updateLead, completedLeadSideEffects, trackOnboardingCompletion } =
      functions
    const { leadUuid } = flowData

    await flattenUpdateLeadHelper(flowData, flowData.leadUuid!, updateLead)
    await completedLeadSideEffects({
      variables: {
        completedLeadSideEffectsInput: {
          leadUuid: leadUuid!,
          sendWelcomeEmail: false,
        },
      },
    })
    trackOnboardingCompletion()
    return flowData
  },
}

export const dstThankYouStep: StepConfig<
  WebflowData,
  WebflowFunctions,
  WebflowStepProps<StaticOnboardingBackgroundProps>,
  InjectedWebflowStepProps
> = {
  ...basicProps('thankYou'),
  renderIf: async (flowData: Partial<WebflowData>) => !!flowData.dstLead,
  componentProps: {
    stepName: 'thankYou',
    progress: 'complete',
    ctaText: 'Finish',
    hideBack: true,
    backgroundComponent: StaticOnboardingBackground,
    backgroundComponentProps: {
      src: OnboardingSplash,
      applyFilter: true,
    },
    inputConfigs: [
      {
        name: 'thankYou',
        type: InputType.CustomComponent,
        props: {
          component: (
            <Box display="flex" flexDirection="column" gap="16px">
              <Typography variant="h1" color="moneyGreen.main">
                Thank you!
              </Typography>
              <Typography variant="h4">We will be in touch soon.</Typography>
            </Box>
          ),
        },
      },
    ],
  },
  onCompletion: async (
    flowData: Partial<WebflowData>,
    functions: WebflowFunctions
  ) => {
    const { updateLead } = functions

    await flattenUpdateLeadHelper(flowData, flowData.leadUuid!, updateLead)

    window.onbeforeunload = null
    window.open(HOMEPAGE_PATH, '_self')
    if (window.top) {
      window.top.postMessage('Close Modal', '*')
    }
    // This line ensures that the next step doesn't render.
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    await new Promise((resolve) => {})

    return flowData
  },
}

export const webflowInputConfigs = [
  taxPlanInitialStep,
  webinarInitialStep,
  enterAddressStep,
  duplicateAddressVerificationStep,
  initialContactInfoStep,

  dstAttributionStep,
  dstThankYouStep,

  earlyExitContactAgentStep,
  homeDetailsStep,
  unit1DetailsStep,
  unit2DetailsStep,
  unit3DetailsStep,
  unit4DetailsStep,
  propertyConditionStep,
  hasMortgageStep,
  mortgageAmountStep,
  hasTenantStep,
  leaseDetailsStep,
  selfReportedValueStep,
  additionalInfoStep,

  dstAttributionStep,
  dstThankYouStep,

  attributionStep,
  thankYouStep,
]
